import React from 'react';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Title from '../components/Title';
import ActionsGrid from '../components/Actions/ActionsGrid';
import ActFooter from '../components/Actions/ActFooter';

import '../styles/global.scss';
import '../styles/categories.scss';

const ActionsCategoryTemplate = ({ pageContext: { category, actionsDict } }) => {
    return (
        <Layout>
            <SEO title={category} />
            <section className="act section">
                <div className="container pageHero pageHeroContent">
                    <Title>
                        <motion.h1
                            initial={{
                                opacity: 0,
                                scale: 0.8,
                                y: 20,
                            }}
                            animate={{
                                scale: 1,
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{
                                duration: 1,
                            }}
                            className="pageHeadline"
                        >
                            {category}
                        </motion.h1>
                    </Title>
                    <p>
                        <Link to="/act" className="backLink">
                            <span class="chevron left"></span> Back to Actions
                        </Link>
                    </p>

                    <ActionsGrid actions={actionsDict} />
                    <ActFooter />
                </div>
            </section>
        </Layout>
    );
};

export default ActionsCategoryTemplate;
